import WorkSection from "../elements/workSection";

export function Home() {
    return (
        <>
            <div id="start" className="introVideoBG videobgContainer whiteText">
                <video autoPlay muted loop className="videobg fade-in">
                    <img src="../media/siteElements/backgroundStill.png" alt="" />
                    <source src="../media/siteElements/backgroundVideolq.mp4" />
                </video>
                <div id="introTextBox" className="bodyText">
                    <h1 className="thinHeader" id="intro">Welcome!</h1>
                    <div className="twoColumns fade-in-up">
                        <div className="leftCol">
                            <p className=" picParagraph">My name is Parker Straus I'm a Minnesota-born graphic design and software engineer with the motivation to inspire. I look to create objects and idea that intend to lead to further inspire other to create even more.<br /><br />
                                I mainly focus in a career-path in front-end and back-end development, which includes business logic and website processing, with the addition of some application development, desktop or mobile. Furthermore I have miscellaneous experience in graphic design which can be proven useful in front-end development.</p>
                        </div>
                        <div className="rightCol"><img className="fade-in-up" id="intropic" src="../media/siteElements/photo.jpg" width="100%" alt="Self Portrait" /></div>
                    </div>
                </div>
            </div>
            <div id="skills" className="videobgContainer ">
                <div className="bodyText">

                    <h1 className="thinHeader">What do I specialize in?</h1>

                </div>
                    <div className="columnGroup">
                        <div className="column"><h1 className="header-column">Front-end Development</h1>
                            <a>I'm verse in many presentation frameworks with the addition of an understanding of Human-Computer interaction and UX-based design.</a>
                            <hr/>
                            <ul className="column-list">
                                <li>HTML</li>
                                <li>CSS</li>
                                <li>REACT.js</li>
                                <li>Angular</li>
                                <li>Javascript</li>
                            </ul>
                        </div>
                        <div className="column"><h1 className="header-column">Back-end Development</h1>
                            <a>I've had experience creating complex internal processes for websites as well as complex internal business logic systems.</a>
                            <hr/>
                            <ul className="column-list">
                                <li>PHP</li>
                                <li>Node.JS</li>
                                <li>Express.JS</li>
                                <li>REST API</li>
                                <li>AXIOS</li>
                                <li>NGrok</li>
                            </ul>
                        </div>
                        <div className="column"><h1 className="header-column">Application Development</h1>
                            <a>Though not as many project under this category, this demonstrates my versatility in other fields of software.</a>
                            <hr/>
                            <ul className="column-list">
                                <li>Android</li>
                                <li>Java</li>
                                <li>C#</li>
                                <ul className="column-list">
                                    <li>WinForms</li>
                                    <li>.Net</li>
                                </ul>
                                <li>React Native</li>
                                <li>C++</li>
                            </ul>
                        </div>

                    </div>
            </div>
            <hr/>
            <div id="work" className="videobgContainer">
                <div className="bodyText">

                    <h1 className="thinHeader">What work have I done?</h1>
                    
                    <WorkSection/>

                </div>
            </div>
            <div id="contact" className="videobgContainer">

                <video autoPlay muted loop className="videobg">
                    <source src="media/siteElements/backgroundVideolq2.mp4" />
                </video>
                <div className="introContact bodyText">
                    <h1 className="thinHeader">Where to find me</h1>
                    <table className="contactInfo fade-in-up">
                        <tr>
                            <th><a href="mailto:parkerstraus@gmail.com"><img src="media/siteElements/email.png" alt="E-Mail" /></a></th><th><a href="mailto:parkerstraus@gmail.com">Email: parkerstraus@gmail.com</a></th>
                        </tr>
                        <tr>
                            <th><a href="https://www.linkedin.com/in/parker-straus/"><img src="media/siteElements/linkedin.png" alt="LinkedIn" /></a></th><th><a href="https://www.linkedin.com/in/parker-straus-0a55141a1/">LinkedIn</a></th>
                        </tr>
                        <tr>
                            <th><a href="https://www.fiverr.com/parkerstraus"><img src="media/siteElements/fiverr.png" alt="Fiverr" /></a></th><th><a href="https://www.fiverr.com/parkerstraus">Fiverr</a></th>
                        </tr>
                        <tr>
                            <th><a href="https://github.com/parkerstraus"><img src="media/siteElements/github.png" alt="Github" /></a></th><th><a href="https://github.com/parkerstraus">Github</a></th>
                        </tr>
                    </table>
                </div>
            </div>
        </>
    );
}
