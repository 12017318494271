import React, { useState, useEffect } from 'react';

const WorkSection = () => {
    const [commissions, setCommissions] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedTags, setSelectedTags] = useState([]);

    useEffect(() => {
        const fetchCommissions = async () => {
            try {
                const response = await fetch('/json/workDone.json');
                const data = await response.json();
                setCommissions(data.commissions);
            } catch (error) {
                console.error('Error fetching commissions:', error);
            }
        };

        fetchCommissions();
    }, []);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleTagClick = (tag) => {
        if (selectedTags.includes(tag)) {
            setSelectedTags(selectedTags.filter((selectedTag) => selectedTag !== tag));
        } else {
            setSelectedTags([...selectedTags, tag]);
        }
    };

    const filteredCommissions = commissions.filter((commission) => {
        const matchesSearchTerm = commission.name.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesTags = selectedTags.every((tag) => commission.code.includes(tag));
        return matchesSearchTerm && matchesTags;
    });

    return (
        <div className="work-section-container">
            <div>
                <input
                    type="text"
                    placeholder="Search commissions..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
                <div>
                    {['C#', 'Java', 'PHP', 'MySQL', 'Node.js', 'React.js', 'Express.js', 'HTML', 'CSS'].map((tag) => (
                        <button
                            key={tag}
                            className={`tag-button ${selectedTags.includes(tag) ? 'selected' : ''}`}
                            onClick={() => handleTagClick(tag)}
                        >
                            {tag}
                        </button>
                    ))}
                </div>
            </div>
            <div className="commission-list">
                {filteredCommissions.map((commission, index) => (
                    <CommissionItem key={commission.id} commission={commission} />
                ))}
            </div>
        </div>
    );
};


const CommissionItem = ({ commission }) => {
    return (
        <div className="commission-item">
            <h3 className='com-item-title'>{commission.name}</h3>
            {commission.img.endsWith(".mp4") ? (
                <video autoPlay muted loop className='com-item-vid'>
                    <source src={commission.img} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            ) : (
                <img src={commission.img} alt={commission.name} />
            )}
            <p className='com-item-desc'>{commission.desc}</p>
            <p className='com-item-code'> {commission.code.join(', ')}</p>
            <p>Work Group: {commission.app}</p>
        </div>
    );
};

export default WorkSection;


