import logo from './logo.svg';
import './App.css';
import './style.css'
import { BrowserRouter, Routes, Route 
} from "react-router-dom";
import Header from "./elements/elements";
import { Home } from './pages/Home';
import { PageNotFound } from './pages/PageNotFound.js';

function App() {


  return (
    <>
    <Header/>
    <BrowserRouter>
      <Routes>
          <Route index element={<Home/>} />
          <Route path='*' element={<PageNotFound/>} />
      </Routes>
    </BrowserRouter>
    </>
  
  );
}

export default App;
