
function myFunction() {
    var x = document.getElementById("nav");
    if (x.style.display === "block") {
        x.style.display = "none";
    } else {
        x.style.display = "block";
    }
}

export default function Header() {
    const jsx = (
        <>
            <div className="topbar">
                <div className="topbarSolid"><a href="#start" className="title"><img className="titleLogo" src = "../media/siteElements/Website logo.svg" alt="Parker Straus" /></a>
                    <img id="hamburgerMenu" src="../media/siteElements/hamburgermenu.png" onClick={myFunction} />
                    <ul id="nav" className="nav">
                        <li className="navlink"><a href="#start" >About</a></li>
                        <li className="navlink"><a href="#skills" >Expertise</a></li>
                        <li className="navlink"><a href="#work" >My Work</a></li>
                        <li className="navlink"><a href="#contact" >Contact</a></li>
                    </ul>
                </div>
                <div className="topshadow"></div>
            </div>
            <div className="topbarSpace"></div>
        </>
    );

    return jsx;
}